@tailwind base;
@tailwind components;

/* purgecss start ignore */
@tailwind utilities;
@tailwind variants;
/* purgecss end ignore */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

/* purgecss start ignore */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('https://d2o2utebsixu4k.cloudfront.net/assets/fonts/open-sans/bold.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('https://d2o2utebsixu4k.cloudfront.net/assets/fonts/open-sans/semi-bold.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('https://d2o2utebsixu4k.cloudfront.net/assets/fonts/open-sans/regular.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
body {
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
  font-family: 'Open Sans', sans-serif !important;
}

/* Global css starts */

.acdtn-icons {
  background: url('https://d5jbouauxtwah.cloudfront.net/eyJidWNrZXQiOiJrbm93bGVkZ2VodXQtcHJlcG8tbGl2ZSIsImtleSI6ImJsb2dzL3Nwcml0ZXMvYWNjcmVkaWF0aW9uLWljb25zLnBuZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb3ZlciJ9fX0=');
  background-repeat: no-repeat;
}

.acdtn-page-1 {
  background-position: -46px -3px;
  width: 34px;
  height: 35px;
}
.acdtn-page-2 {
  background-position: -213px -8px;
  width: 91px;
  height: 24px;
}
.acdtn-page-3 {
  background-position: -404px 0;
  width: 97px;
  height: 40px;
}
.acdtn-page-4 {
  background-position: -601px -8px;
  width: 90px;
  height: 24px;
}
.acdtn-page-5 {
  background-position: -779px -7px;
  width: 92px;
  height: 26px;
}
.acdtn-page-6 {
  background-position: -943px -12px;
  width: 91px;
  height: 16px;
}
.acdtn-page-7 {
  background-position: -1117px -1px;
  width: 36px;
  height: 38px;
}
.acdtn-page-8 {
  background-position: -1252px -4px;
  width: 91px;
  height: 33px;
}
.acdtn-page-9 {
  background-position: -31px -105px;
  width: 63px;
  height: 53px;
}
.acdtn-page-10 {
  background-position: -213px -126px;
  width: 91px;
  height: 10px;
}
.acdtn-page-11 {
  background-position: -406px -117px;
  width: 92px;
  height: 28px;
}
.acdtn-page-12 {
  background-position: -628px -112px;
  width: 36px;
  height: 39px;
}
.acdtn-page-13 {
  background-position: -797px -104px;
  width: 56px;
  height: 54px;
}
.acdtn-page-14 {
  background-position: -942px -111px;
  width: 93px;
  height: 41px;
}
.acdtn-page-15 {
  background-position: -1116px -112px;
  width: 39px;
  height: 39px;
}
.acdtn-page-16 {
  background-position: -1250px -115px;
  width: 96px;
  height: 32px;
}
.acdtn-page-17 {
  background-position: -46px -228px;
  width: 34px;
  height: 43px;
}
.acdtn-page-18 {
  background-position: -211px -225px;
  width: 95px;
  height: 48px;
}
.acdtn-page-19 {
  background-position: -407px -236px;
  width: 90px;
  height: 26px;
}
.acdtn-page-20 {
  background-position: -799px -341px;
  width: 52px;
  height: 51px;
}
.acdtn-page-21 {
  background-position: -583px -222px;
  width: 125px;
  height: 54px;
}
.acdtn-page-22 {
  background-position: -803px -223px;
  width: 44px;
  height: 53px;
}
.acdtn-page-23 {
  background-position: -1106px -224px;
  width: 58px;
  height: 51px;
}
.acdtn-page-24 {
  background-position: -963px -224px;
  width: 51px;
  height: 50px;
}
.acdtn-page-25 {
  background-position: -1235px -224px;
  width: 125px;
  height: 51px;
}
.acdtn-page-26 {
  background-position: 0 -342px;
  width: 125px;
  height: 49px;
}
.acdtn-page-27 {
  background-position: -196px -343px;
  width: 125px;
  height: 48px;
}
.acdtn-page-28 {
  background-position: -392px -340px;
  width: 120px;
  height: 54px;
}
.acdtn-page-29 {
  background-position: -615px -340px;
  width: 62px;
  height: 54px;
}
.acdtn-page-30 {
  background-position: -815px -698px;
  width: 38px;
	height: 38px;
}
.acdtn-page-31 {
  background-position: -1100px -351px;
  width: 70px;
  height: 32px;
}
.acdtn-page-32 {
  background-position: -1231px -348px;
  width: 117px;
  height: 37px;
}
.acdtn-page-33 {
  background-position: -37px -451px;
  width: 54px;
  height: 53px;
}
.acdtn-page-34 {
  background-position: -196px -461px;
  width: 122px;
  height: 32px;
}
.acdtn-page-35 {
  background-position: -394px -458px;
  width: 79px;
  height: 38px;
}
.acdtn-page-36 {
  background-position: -591px -455px;
  width: 89px;
  height: 45px;
}
.acdtn-page-37 {
  background-position: -798px -451px;
  width: 53px;
  height: 53px;
}
.acdtn-page-38 {
  background-position: -949px -445px;
  width: 94px;
  height: 54px;
}
.acdtn-page-49 {
  background-position: -10px -574px;
  width: 48px;
  height: 48px;
}
.acdtn-page-50 {
  background-position: -144px -584px;
  width: 110px;
  height: 28px;
}
.acdtn-page-51 {
  background-position: -343px -582px;
  width: 107px;
  height: 32px;
}
.acdtn-page-52 {
  background-position: -538px -580px;
  width: 100px;
  height: 36px;
}
.acdtn-page-53 {
  background-position: -733px -576px;
  width: 85px;
  height: 42px;
}
.acdtn-page-54 {
  background-position: -918px -590px;
  width: 108px;
  height: 24px;
}
.acdtn-page-55 {
  background-position: -1122px -590px;
  width: 97px;
  height: 22px;
}
.acdtn-page-56 {
  background-position: -17px -709px;
  width: 106px;
  height: 23px;
}
.acdtn-page-57 {
  background-position: -221px -696px;
  width: 88px;
  height: 38px;
}
.acdtn-page-58 {
  background-position: -417px -696px;
  width: 34px;
  height: 40px;
}
.acdtn-page-64 {
  background-position: -542px -696px;
  width: 34px;
  height: 40px;
}
.acdtn-page-65 {
  background-position: -680px -697px;
  width: 34px;
  height: 40px;
}

.acdtn-page-68 {
  background-position:-953px -696px;
	width: 40px;
	height: 40px;
}

.blog-icons {
  background: url('https://d2o2utebsixu4k.cloudfront.net/blogs/sprites/blogs-icon.svg');
  background-repeat: no-repeat;
}
.icon-maestro {
  background-position: -495px -61px;
  width: 39px;
  height: 24px;
}
.icon-american-express {
  background-position: -594px -61px;
  width: 39px;
  height: 24px;
}
.icon-paypal {
  background-position: -693px -61px;
  width: 39px;
  height: 24px;
}
.icon-visa {
  background-position: -792px -61px;
  width: 39px;
  height: 24px;
}
.icon-master-card {
  background-position: -891px -61px;
  width: 39px;
  height: 24px;
}
.icon-citrus {
  background-position: -990px -61px;
  width: 39px;
  height: 24px;
}
.icon-after-pay {
  background-position: -1089px -61px;
  width: 69px;
  height: 24px;
}
.icon-usa {
  background-position: -1218px -66px;
  width: 27px;
  height: 15px;
}
.icon-uk {
  background-position: -1305px -66px;
  width: 27px;
  height: 15px;
}
.icon-india {
  background-position: 0 -124px;
  width: 27px;
  height: 15px;
}
.icon-singapore {
  background-position: -87px -124px;
  width: 27px;
  height: 15px;
}
.icon-canada {
  background-position: -174px -124px;
  width: 27px;
  height: 15px;
}
.icon-new-zealand {
  background-position: -261px -124px;
  width: 27px;
  height: 15px;
}
.icon-australia {
  background-position: -348px -124px;
  width: 27px;
  height: 15px;
}
.icon-uae {
  background-position: -435px -124px;
  width: 27px;
  height: 15px;
}
.icon-malaysia {
  background-position: -596px -124px;
  width: 27px;
  height: 15px;
}
.icon-ireland {
  background-position: -683px -124px;
  width: 27px;
  height: 15px;
}
.icon-linkedin-large {
  background-position: -5px -63px;
  width: 18px;
  height: 20px;
}
.icon-facebook-large {
  background-position: -89px -63px;
  width: 13px;
  height: 20px;
}
.icon-rss-large {
  background-position: -694px -462px;
  width: 18px;
  height: 18px;
}

.exit-refer-and-earn-icon {
  background-position: -213px -642px !important;
  width: 8px;
  height: 8px;
}

.exit-icon-close {
  background-position: -704px -553px !important;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
}

.exit-icon-close-gray {
  background-position: -945px -358px !important;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
}

.exit-download-icon {
  background-position: -33px -635px !important;
  width: 20px;
  height: 20px;
}

.exit-download-icon-small {
  background-position: -33px -635px !important;
  width: 20px;
  height: 20px;
}

.exit-linkedIn-icon {
  background-position: -295px -638px !important;
  width: 32px;
  height: 32px;
}

.exit-twitter-icon {
  background-position: -397px -638px !important;
  width: 32px;
  height: 32px;
}

.exit-instagram-icon {
  background-position: -499px -638px !important;
  width: 32px;
  height: 32px;
}
.exit-facebook-icon {
  background-position: -601px -638px !important;
  width: 32px;
  height: 32px;
}

.exit-linkedIn-small-icon {
  background-position: -789px -645px !important;
  width: 24px;
  height: 24px;
}

.exit-twitter-small-icon {
  background-position: -883px -645px !important;
  width: 24px;
  height: 24px;
}

.exit-instagram-small-icon {
  background-position: -977px -645px !important;
  width: 24px;
  height: 24px;
}

.exit-facebook-small-icon {
  background-position: -1071px -645px !important;
  width: 24px;
  height: 24px;
}

.course-836 {
  background-position: -491px -4327px;
}
.course-837 {
  background-position: -592px -4327px;
}
.course-838 {
  background-position: -692px -4327px;
}
.course-839 {
  background-position: -21px -4418px;
}
.course-840 {
  background-position: -132px -4418px;
}
.course-841 {
  background-position: -241px -4418px;
}

.course-842 {
  background-position: -361px -4419px;
}
.course-843 {
  background-position: -480px -4420px;
}
.course-866 {
  background-position: -577px -4725px !important;
}

.course-867 {
  background-position: -686px -4721px !important;
}
.course-868 {
  background-position: -39px -4839px !important;
}
.course-869 {
  background-position: -151px -4836px !important;
}
.course-870 {
  background-position: -258px -4837px !important;
}

.blog-icons {
  background: url('https://d2o2utebsixu4k.cloudfront.net/blogs/sprites/blogs-icon.svg');
  background-repeat: no-repeat;
}
.icon-maestro {
  background-position: -495px -61px;
  width: 39px;
  height: 24px;
}
.icon-american-express {
  background-position: -594px -61px;
  width: 39px;
  height: 24px;
}
.icon-paypal {
  background-position: -693px -61px;
  width: 39px;
  height: 24px;
}
.icon-visa {
  background-position: -792px -61px;
  width: 39px;
  height: 24px;
}
.icon-master-card {
  background-position: -891px -61px;
  width: 39px;
  height: 24px;
}
.icon-citrus {
  background-position: -990px -61px;
  width: 39px;
  height: 24px;
}
.icon-after-pay {
  background-position: -1089px -61px;
  width: 69px;
  height: 24px;
}
.icon-usa {
  background-position: -1218px -66px;
  width: 27px;
  height: 15px;
}
.icon-uk {
  background-position: -1305px -66px;
  width: 27px;
  height: 15px;
}
.icon-india {
  background-position: 0 -124px;
  width: 27px;
  height: 15px;
}
.icon-singapore {
  background-position: -87px -124px;
  width: 27px;
  height: 15px;
}
.icon-canada {
  background-position: -174px -124px;
  width: 27px;
  height: 15px;
}
.icon-new-zealand {
  background-position: -261px -124px;
  width: 27px;
  height: 15px;
}
.icon-australia {
  background-position: -348px -124px;
  width: 27px;
  height: 15px;
}
.icon-uae {
  background-position: -435px -124px;
  width: 27px;
  height: 15px;
}
.icon-malaysia {
  background-position: -596px -124px;
  width: 27px;
  height: 15px;
}
.icon-ireland {
  background-position: -683px -124px;
  width: 27px;
  height: 15px;
}
.icon-linkedin-large {
  background-position: -5px -63px;
  width: 18px;
  height: 20px;
}
.icon-facebook-large {
  background-position: -89px -63px;
  width: 13px;
  height: 20px;
}
.icon-rss-large {
  background-position: -694px -462px;
  width: 18px;
  height: 18px;
}

.icon-instagram {
  background-position: -168px -63px;
  width: 20px;
  height: 20px;
}
.icon-youtube {
  background-position: -248px -63px;
  width: 24px;
  height: 21px;
}
.icon-twitter-large {
  background-position: -332px -64px;
  width: 21px;
  height: 17px;
}
.icon-pinterest {
  background-position: -414px -62px;
  width: 19px;
  height: 23px;
}

.icon-down-arrow-black {
  background-position: -232px -8px;
  width: 12px;
  height: 8px;
}

.icon-right-arrow-pink {
  background-position: -689px -6px;
  width: 8px;
  height: 13px;
}

/* Scroll bar styling for mega menu & menu items in header */
.customized-scrollbar {
  overflow-y: scroll;
  scrollbar-color: #ddd #fff;
  scrollbar-width: thin;
}
.customized-scrollbar::-webkit-scrollbar {
  width: 5px;
}
.customized-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(209 213 219);
}
.customized-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
}

/* footer sticky bar icons */

.footer-sticky-bar-icon {
  background: url('https://d5jbouauxtwah.cloudfront.net/eyJidWNrZXQiOiJrbm93bGVkZ2VodXQtcHJlcG8tbGl2ZSIsImtleSI6ImFzc2V0cy9pY29ucy9pY29uLXNwcml0ZS5wbmciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY292ZXIifX19');
  background-repeat: no-repeat;
}

.footer-call-icon {
  background-position: -642px -1285px;
  width: 16px;
  height: 16px;
}

.footer-mail-icon {
  background-position: -682px -1287px;
  width: 16px;
  height: 12px;
}

.footer-query-icon {
  background-position: -643px -1334px;
  width: 15px;
  height: 17px;
}

.footer-advisor-icon {
  background-position: -725px -1286px;
  width: 11px;
  height: 15px;
}

.footer-schedule-icon {
  background-position: -683px -1336px;
  width: 14px;
  height: 13px;
}

/* header icons */

.sidebar-icon {
  background: url('https://d5jbouauxtwah.cloudfront.net/eyJidWNrZXQiOiJrbm93bGVkZ2VodXQtcHJlcG8tbGl2ZSIsImtleSI6ImFzc2V0cy9pbWFnZXMvaWNvbnMvaGVhZGVyX3Nwcml0ZS5wbmciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY292ZXIifX19');
  background-repeat: no-repeat;
}

.sidebar-arrow-down-icon {
  background-position: -453px -39px;
  width: 14px;
  height: 9px;
}
.sidebar-arrow-up-pink-icon {
  background-position: -520px -132px;
  width: 12px;
  height: 8px;
}

.info-icon {
  background: url('https://d2o2utebsixu4k.cloudfront.net/kh_course_latest_sprite.svg') no-repeat -165px -153px;
  width: 18px;
  height: 18px;
}

.form-success-popup-icon {
  background: url('https://d2o2utebsixu4k.cloudfront.net/assets/images/icons/form-green-tick.gif') no-repeat;
  width: 100px;
  height: 100px;
}

/* .form-checkbox {
  display: grid;
  place-content: center;
  border-radius: 10px;
  position: relative;
} */

.form-checkbox[type='checkbox'] + span::before {
  content: '';
  position: absolute;
  top: 4px;
  width: 22px;
  height: 22px;
  border: 1px solid gray;
  border-radius: 2px;
}

.form-checkbox[type='checkbox']:checked + span::before {
  content: '';
  position: absolute;
  width: 22px;
  height: 22px;
  background: linear-gradient(150deg, #ff702a 0%, #c507c9 100%);
  border-radius: 2px;
  border: none;
}

.form-checkbox[type='checkbox']:checked + span::after {
  content: '';
  position: absolute;
  background: url('https://d2o2utebsixu4k.cloudfront.net/kh_course_latest_sprite.svg') no-repeat -9px -156px;
  width: 12px;
  height: 12px;
  transform: translate(43%, 83%);
  scale: 1.1;
  filter: brightness(10);
}

/* .form-checkbox-small {
  display: grid;
  place-content: center;
  border-radius: 10px;
  position: absolute !important;
  left: 0 !important;
} */

.form-checkbox-small[type='checkbox'] + span::before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border: 1px solid gray;
  border-radius: 2px;
}

.form-checkbox-small[type='checkbox']:checked + span::before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  background: linear-gradient(150deg, #ff702a 0%, #c507c9 100%);
  border-radius: 2px;
  border: none;
}

.form-checkbox-small[type='checkbox']:checked + span::after {
  content: '';
  position: absolute;
  background: url('https://d2o2utebsixu4k.cloudfront.net/kh_course_latest_sprite.svg') no-repeat -9px -156px;
  width: 12px;
  height: 12px;
  scale: 0.9;
  filter: brightness(10);
  transform: translate(20%, 30%);
}

.form-checkbox-small-master[type='checkbox'] + span::before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  border: 1px solid gray;
  border-radius: 2px;
}

.form-checkbox-small-master[type='checkbox']:checked + span::before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  background: #586feb;
  border-radius: 2px;
  border: none;
}

.form-checkbox-small-master[type='checkbox']:checked + span::after {
  content: '';
  position: absolute;
  background: url('https://d2o2utebsixu4k.cloudfront.net/kh_course_latest_sprite.svg') no-repeat -9px -156px;
  width: 14px;
  height: 14px;
  scale: 0.9;
  filter: brightness(10);
  transform: translate(26%, 35%);
}

/* Terms and condition for europe region */
.mc-form-checkbox[type='checkbox'] + span::before {
  content: '';
  position: absolute;
  top: 4px;
  width: 22px;
  height: 22px;
  border: 1px solid gray;
  border-radius: 2px;
}

.mc-form-checkbox[type='checkbox']:checked + span::before {
  content: '';
  position: absolute;
  width: 22px;
  height: 22px;
  background: #586feb;
  border-radius: 2px;
  border: none;
}

.mc-form-checkbox[type='checkbox']:checked + span::after {
  content: '';
  position: absolute;
  background: url('https://d2o2utebsixu4k.cloudfront.net/kh_course_latest_sprite.svg') no-repeat -9px -156px;
  width: 12px;
  height: 12px;
  transform: translate(43%, 83%);
  scale: 1.1;
  filter: brightness(10);
}

.watch-video-icon {
  background-image: url(https://d2o2utebsixu4k.cloudfront.net/kh_course_latest_sprite.svg);
  background-repeat: no-repeat;
  background-position: -111px -232px;
  width: 22px;
  height: 22px;
}

.next-image-styles span {
  display: block !important;
  width: 100% !important;
  padding: 0 !important;
}

.next-image-styles span img {
  position: static !important;
}

@media only screen and (max-width: 1024px) {
  .zopim {
    bottom: 60px !important;
    z-index: 99 !important;
  }
}

@media (max-width: 960px) {
  .banner-background-image {
    background-image: none !important;
  }
}

#mega-menu-right-arrow span {
  display: inline-block;
  transition: 0.2s ease-in;
}

#mega-menu-right-arrow:hover span {
  transform: translateX(30%);
}

/* icon course comment */
.icons-course {
  background-image: url('https://d5jbouauxtwah.cloudfront.net/eyJidWNrZXQiOiJrbm93bGVkZ2VodXQtcHJlcG8tbGl2ZSIsImtleSI6ImFzc2V0cy9pY29ucy9jb3Vyc2Utc3ByaXRlLTMyLnBuZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJjb3ZlciJ9fX0=');
  background-repeat: no-repeat;
  background-position: -708px -4009px;
  height: 82px;
  width: 82px;
  display: inline-block;
}
.course-1 {
  background-position: -8px 2px;
}
.course-2 {
  background-position: -310px 3px;
}
.course-3 {
  background-position: -208px -999px;
}
.course-4 {
  background-position: -95px -1095px;
}
.course-5 {
  background-position: -510px 3px;
}
.course-6 {
  background-position: -610px -597px;
}
.course-8 {
  background-position: -609px -997px;
}
.course-10 {
  background-position: -207px -100px;
}
.course-11 {
  background-position: -508px -1500px;
}
.course-12 {
  background-position: -301px -1497px;
}
.course-13 {
  background-position: -313px -999px;
}
.course-14 {
  background-position: -306px -403px;
}
.course-15 {
  background-position: -610px 3px;
}
.course-16 {
  background-position: -710px 3px;
}
.course-17 {
  background-position: -209px 3px;
}
.course-18 {
  background-position: -5px -1696px;
}
.course-19 {
  background-position: -11px -203px;
}
/*.course-20{background-position:-511px -103px;}*/
.course-20 {
  background-position: -5px -2294px;
}
.course-21 {
  background-position: -502px -1201px;
}
.course-22 {
  background-position: -599px -1200px;
}
.course-24 {
  background-position: -310px -700px;
}
.course-23 {
  background-position: -410px -699px;
}
.course-25 {
  background-position: -510px -703px;
}
.course-27 {
  background-position: -610px -795px;
}
.course-28 {
  background-position: -210px -401px;
}
/*.course-29{background-position:-7px -899px;}*/
.course-29 {
  background-position: -611px -2200px;
}
.course-30 {
  background-position: -299px -1304px;
}
/*.course-31{background-position:-603px -397px;}*/
.course-31 {
  background-position: -708px -2199px;
}
.course-32 {
  background-position: -0px -1300px;
}
.course-33 {
  background-position: -713px -2098px;
}
.course-34 {
  background-position: -110px -898px;
}
.course-35 {
  background-position: -214px -898px;
}
.course-36 {
  background-position: -410px -203px;
}
.course-37 {
  background-position: -710px -795px;
}
.course-38 {
  background-position: -299px -1095px;
}
.course-39 {
  background-position: -400px -1095px;
}
.course-40 {
  background-position: -502px -1095px;
}
.course-41 {
  background-position: -600px -1095px;
}
.course-42 {
  background-position: -700px -1095px;
}
.course-43 {
  background-position: -100px -1198px;
}
.course-44 {
  background-position: -200px -1196px;
}
.course-45 {
  background-position: -0px -1198px;
}
.course-46 {
  background-position: -411px -1500px;
}
.course-47 {
  background-position: -413px -2505px;
}
.course-48 {
  background-position: -309px -2504px;
}
.course-49 {
  background-position: -203px -1696px;
}
.course-50 {
  background-position: -307px -1700px;
}
.course-51 {
  background-position: -210px -203px;
}
.course-52 {
  background-position: -310px -203px;
}
.course-53 {
  background-position: -108px -1902px;
}
.course-55 {
  background-position: -700px -1198px;
}
.course-57 {
  background-position: -8px -503px;
}
.course-58 {
  background-position: -210px -503px;
}
.course-59 {
  background-position: -110px -600px;
}
.course-62 {
  background-position: -309px -103px;
}
.course-63 {
  background-position: -714px -1597px;
}
.course-64 {
  background-position: -105px -1696px;
}
.course-65 {
  background-position: -711px -98px;
}
.course-66 {
  background-position: -110px -403px;
}
.course-67 {
  background-position: -305px -1803px;
}
.course-69 {
  background-position: -212px -1999px;
}
.course-72 {
  background-position: -106px -1799px;
}
.course-73 {
  background-position: -211px -1901px;
}
.course-75 {
  background-position: -309px -1903px;
}
.course-76 {
  background-position: -707px -1903px;
}
.course-77 {
  background-position: -8px -1999px;
}
.course-78 {
  background-position: -507px -1903px;
}
.course-83 {
  background-position: -713px -2396px;
}
.course-84 {
  background-position: -110px -2502px;
}
.course-85 {
  background-position: -9px -2500px;
}
.course-86 {
  background-position: -404px -1799px;
}
.course-87 {
  background-position: -710px -203px;
}
.course-88 {
  background-position: -512px -2503px;
}
.course-89 {
  background-position: -8px -303px;
}
.course-104 {
  background-position: -407px -1903px;
}
.course-106 {
  background-position: -612px -2000px;
}
.course-108 {
  background-position: -110px -303px;
}
.course-109 {
  background-position: -713px -1697px;
}
.course-110 {
  background-position: -8px -999px;
}
.course-111 {
  background-position: -10px -503px;
}
.course-114 {
  background-position: -7px -1799px;
}
.course-116 {
  background-position: -406px -1701px;
}
.course-117 {
  background-position: -206px -1501px;
}
.course-118 {
  background-position: -610px -1804px;
}
.course-120 {
  background-position: -210px -603px;
}
.course-121 {
  background-position: -203px -1402px;
}
.course-123 {
  background-position: -210px -303px;
}
.course-125 {
  background-position: -509px -1702px;
}
.course-127 {
  background-position: -404px -1599px;
}
.course-131 {
  background-position: -608px -1698px;
}
.course-133 {
  background-position: -4px -1398px;
}
.course-134 {
  background-position: -105px -1397px;
}
.course-135 {
  background-position: -302px -1395px;
}
.course-136 {
  background-position: -400px -1397px;
}
.course-137 {
  background-position: -501px -1395px;
}
.course-138 {
  background-position: -594px -1394px;
}
.course-139 {
  background-position: -698px -1394px;
}
.course-140 {
  background-position: -1px -1502px;
}
.course-141 {
  background-position: -100px -1500px;
}
.course-144 {
  background-position: -611px -98px;
}
.course-146 {
  background-position: -510px -803px;
}
.course-147 {
  background-position: -210px -703px;
}
.course-149 {
  background-position: -513px -1800px;
}
.course-150 {
  background-position: -410px 3px;
}
.course-151 {
  background-position: -305px -1596px;
}
/*.course-152{background-position:-110px -203px;}*/
.course-152 {
  background-position: -110px -203px;
}
.course-154 {
  background-position: -310px -895px;
}
.course-157 {
  background-position: -205px -1599px;
}
.course-158 {
  background-position: -510px -203px;
}
.course-159 {
  background-position: -610px -203px;
}
.course-160 {
  background-position: -8px -703px;
}
.course-161 {
  background-position: -110px -703px;
}
.course-162 {
  background-position: -606px -1903px;
}
/*.course-163{background-position:-8px -603px;}*/
.course-163 {
  background-position: -6px -2202px;
}
.course-169 {
  background-position: -110px -503px;
}
.course-170 {
  background-position: -310px -603px;
}
.course-171 {
  background-position: -513px -1998px;
}
.course-173 {
  background-position: -710px -303px;
} /*nodejs*/
.course-178 {
  background-position: -410px -503px;
} /*ruby on rails 101*/
.course-183 {
  background-position: -206px -2508px;
}
.course-184 {
  background-position: -408px -999px;
} /*introduction to data science*/
.course-192 {
  background-position: -501px -1303px;
}
.course-195 {
  background-position: -310px -503px;
} /*react js*/
.course-196 {
  background-position: -410px -603px;
} /*xamarine*/
.course-197 {
  background-position: -108px -999px;
} /*data visualization*/
.course-208 {
  background-position: -410px -803px;
} /*cloudera*/
.course-211 {
  background-position: -700px -1302px;
}
.course-212 {
  background-position: -10px -1904px;
}
.course-213 {
  background-position: -299px -1204px;
}
.course-214 {
  background-position: -8px -2000px;
}
.course-215 {
  background-position: -109px -2197px;
}
.course-217 {
  background-position: -198px -1095px;
}
.course-227 {
  background-position: -708px -896px;
} /*Data Harvesting and R Essentials training*/
.course-228 {
  background-position: -508px -999px;
} /*R for*/
.course-230 {
  background-position: -8px -403px;
} /*nodekvm*/
.course-234 {
  background-position: -511px -2202px;
} /*Professional Scrum Developer‚(PSD) training*/
/*.course-234{background-position:-410px -403px;}*/
.course-237 {
  background-position: -7px -2098px;
}
.course-238 {
  background-position: -410px -2399px;
} /*aws developer*/
.course-239 {
  background-position: -208px -2097px;
}
.course-241 {
  background-position: -310px -2179px;
}
.course-242 {
  background-position: -511px -2094px;
}
.course-243 {
  background-position: -411px -2095px;
}
.course-244 {
  background-position: -410px -2000px;
}
.course-247 {
  background-position: -111px -2099px;
}
.course-248 {
  background-position: -308px -2097px;
}

.course-245 {
  background-position: -310px -2005px;
}
.course-246 {
  background-position: -8px -1598px;
}
/*.course-250{background-position:-714px -1803px;}*/
.course-250 {
  background-position: -410px -2179px;
}
.course-249 {
  background-position: -710px -597px;
} /*course driven development*/
.course-257 {
  background-position: -210px -2179px;
}
.course-274 {
  background-position: -609px -2100px;
}
.course-289 {
  background-position: -105px -2295px;
} /*a-csm*/
.course-290 {
  background-position: -208px -2294px;
} /*a-cspo*/
.course-291 {
  background-position: -309px -2300px;
} /*scaled-profesiional-scrum*/
.course-292 {
  background-position: -112px -2397px;
}
.course-293 {
  background-position: -208px -2398px;
}
.course-302 {
  background-position: -307px -2400px;
}
.course-303 {
  background-position: -510px -2398px;
}
.course-304 {
  background-position: -609px -2376px;
}
.course-312 {
  background-position: -715px -2502px;
}

.course-700 {
  background-position: -609px -2498px;
}
.course-701 {
  background-position: -9px -2601px;
}
.course-702 {
  background-position: -114px -2601px;
}
.course-703 {
  background-position: -210px -2601px;
}
.course-704 {
  background-position: -510px -2601px;
}
.course-705 {
  background-position: -609px -2601px;
}
.course-706 {
  background-position: -712px -2601px;
}
.course-707 {
  background-position: -10px -2700px;
}
.course-708 {
  background-position: -112px -2700px;
}
.course-709 {
  background-position: -209px -2700px;
}
.course-710 {
  background-position: -310px -2700px;
}
.course-711 {
  background-position: -413px -2700px;
}
.course-712 {
  background-position: -513px -2700px;
}
.course-713 {
  background-position: -611px -2698px;
}
.course-714 {
  background-position: -712px -2700px;
}
.course-715 {
  background-position: -11px -2804px;
}
.course-716 {
  background-position: -107px -2800px;
}
.course-717 {
  background-position: -213px -2800px;
}
.course-718 {
  background-position: -311px -2800px;
}
.course-719 {
  background-position: -411px -2802px;
}
.course-720 {
  background-position: -513px -2801px;
}
.course-721 {
  background-position: -611px -2801px;
}
.course-722 {
  background-position: -712px -2806px;
}
.course-723 {
  background-position: -13px -2901px;
}
.course-724 {
  background-position: -110px -2901px;
}
.course-725 {
  background-position: -208px -2896px;
}
.course-726 {
  background-position: -310px -2899px;
}
.course-727 {
  background-position: -410px -2899px;
}
.course-728 {
  background-position: -510px -2910px;
}
.course-729 {
  background-position: -606px -2901px;
}
.course-730 {
  background-position: -711px -2904px;
}
.course-731 {
  background-position: -11px -3011px;
}
.course-732 {
  background-position: -108px -3013px;
}
.course-733 {
  background-position: -208px -3010px;
}
.course-734 {
  background-position: -308px -3009px;
}
.course-735 {
  background-position: -408px -3008px;
}
.course-736 {
  background-position: -508px -3009px;
}
.course-737 {
  background-position: -610px -3009px;
}
.course-738 {
  background-position: -715px -3008px;
}
.course-739 {
  background-position: -8px -3111px;
}
.course-740 {
  background-position: -108px -3111px;
}
.course-741 {
  background-position: -208px -3111px;
}
.course-742 {
  background-position: -308px -3111px;
}
.course-743 {
  background-position: -408px -3111px;
}
.course-744 {
  background-position: -508px -3111px;
}
.course-745 {
  background-position: -611px -3112px;
}
.course-746 {
  background-position: -710px -3111px;
}
.course-747 {
  background-position: -9px -3211px;
}
.course-748 {
  background-position: -109px -3209px;
}
.course-749 {
  background-position: -208px -3211px;
}
.course-750 {
  background-position: -310px -3213px;
}
.course-751 {
  background-position: -409px -3211px;
}
.course-752 {
  background-position: -510px -3215px;
}
.course-753 {
  background-position: -609px -3211px;
}
.course-754 {
  background-position: -710px -3211px;
}
.course-755 {
  background-position: -10px -3311px;
}
.course-756 {
  background-position: -110px -3310px;
}
.course-757 {
  background-position: -210px -3310px;
}
.course-758 {
  background-position: -310px -3310px;
}
.course-759 {
  background-position: -410px -3310px;
}
.course-760 {
  background-position: -509px -3310px;
}
.course-761 {
  background-position: -609px -3313px;
}
.course-762 {
  background-position: -710px -3310px;
}
.course-763 {
  background-position: -13px -3413px;
}
.course-764 {
  background-position: -109px -3409px;
}

.course-765 {
  background-position: -210px -3410px;
}
.course-766 {
  background-position: -310px -3410px;
}
.course-767 {
  background-position: -410px -3410px;
}
.course-768 {
  background-position: -510px -3410px;
}
.course-769 {
  background-position: -612px -3410px;
}
.course-770 {
  background-position: -710px -3410px;
}
.course-771 {
  background-position: -10px -3511px;
}
.course-772 {
  background-position: -110px -3510px;
}
.course-773 {
  background-position: -210px -3510px;
}
.course-774 {
  background-position: -310px -3509px;
}
.course-775 {
  background-position: -410px -3509px;
}
.course-776 {
  background-position: -510px -3509px;
}
.course-777 {
  background-position: -610px -3509px;
}
.course-778 {
  background-position: -710px -3510px;
}
.course-779 {
  background-position: -11px -3610px;
}
.course-780 {
  background-position: -111px -3609px;
}
.course-781 {
  background-position: -211px -3609px;
}
.course-782 {
  background-position: -311px -3609px;
}
.course-783 {
  background-position: -411px -3610px;
}
.course-784 {
  background-position: -510px -3610px;
}
.course-785 {
  background-position: -610px -3609px;
}
.course-786 {
  background-position: -710px -3610px;
}
.course-787 {
  background-position: -11px -3710px;
}
.course-788 {
  background-position: -110px -3710px;
}
.course-789 {
  background-position: -210px -3710px;
}
.course-790 {
  background-position: -310px -3709px;
}
.course-791 {
  background-position: -410px -3709px;
}
.course-792 {
  background-position: -510px -3710px;
}
.course-793 {
  background-position: -609px -3709px;
}
.course-794 {
  background-position: -710px -3709px;
}
.course-795 {
  background-position: -11px -3809px;
}
.course-796 {
  background-position: -110px -3809px;
}
.course-797 {
  background-position: -210px -3809px;
}
.course-798 {
  background-position: -310px -3809px;
}
.course-799 {
  background-position: -410px -3809px;
}
.course-800 {
  background-position: -510px -3809px;
}
.course-801 {
  background-position: -611px -3810px;
}
.course-802 {
  background-position: -710px -3810px;
}
.course-803 {
  background-position: -11px -3910px;
}
.course-804 {
  background-position: -110px -3910px;
}
.course-805 {
  background-position: -210px -3909px;
}
.course-806 {
  background-position: -310px -3910px;
}
.course-807 {
  background-position: -410px -3910px;
}
.course-808 {
  background-position: -511px -3910px;
}
.course-809 {
  background-position: -611px -3910px;
}
.course-810 {
  background-position: -711px -3910px;
}
.course-811 {
  background-position: -11px -4009px;
}
.course-812 {
  background-position: -111px -4009px;
}
.course-813 {
  background-position: -211px -4009px;
}
.course-814 {
  background-position: -311px -4009px;
}
.course-815 {
  background-position: -411px -4009px;
}
.course-816 {
  background-position: -511px -4009px;
}
.course-817 {
  background-position: -611px -4010px;
}

/* safe course logos css */
.course-818 {
  background-position: -18px -4120px;
}
.course-819 {
  background-position: -111px -4120px;
}
.course-820 {
  background-position: -212px -4120px;
}
.course-821 {
  background-position: -313px -4120px;
}
.course-822 {
  background-position: -414px -4120px;
}
.course-823 {
  background-position: -515px -4120px;
}
.course-824 {
  background-position: -616px -4120px;
}
.course-825 {
  background-position: -709px -4120px;
}
.course-826 {
  background-position: -18px -4225px;
}
.course-827 {
  background-position: -111px -4225px;
}
.course-828 {
  background-position: -212px -4225px;
}
/* safe course logos css */

.course-829 {
  background-position: -310px -4221px;
}
.course-830 {
  background-position: -409px -4221px;
}
.course-831 {
  background-position: -516px -4221px;
}
.course-832 {
  background-position: -616px -4221px;
}

.course-833 {
  background-position: -135px -4310px;
}
.course-834 {
  background-position: -259px -4325px;
}
.course-835 {
  background-position: -379px -4324px;
}

.course-836 {
  background-position: -491px -4327px;
}
.course-837 {
  background-position: -592px -4327px;
}
.course-838 {
  background-position: -692px -4327px;
}
.course-839 {
  background-position: -21px -4418px;
}
.course-840 {
  background-position: -132px -4418px;
}
.course-841 {
  background-position: -241px -4418px;
}

.course-842 {
  background-position: -361px -4419px;
}
.course-843 {
  background-position: -480px -4420px;
}

.course-844 {
  background-position: -37px -4518px;
}
.course-845 {
  background-position: -147px -4518px;
}
.course-846 {
  background-position: -257px -4518px;
}
.course-847 {
  background-position: -367px -4520px;
}
.course-848 {
  background-position: -477px -4518px;
}
.course-849 {
  background-position: -587px -4520px;
}
.course-850 {
  background-position: -697px -4522px;
}

.course-851 {
  background-position: -595px -4420px;
}

.course-852 {
  background-position: -692px -4418px;
}

.course-853 {
  background-position: -709px -4217px;
}

.course-854 {
  background-position: -37px -4628px;
}

.course-855 {
  background-position: -147px -4628px;
}

.course-856 {
  background-position: -255px -4620px;
}

.course-857 {
  background-position: -363px -4628px;
}

.course-858 {
  background-position: -471px -4627px;
}

.course-859 {
  background-position: -581px -4623px;
}

.course-860 {
  background-position: -688px -4620px;
}

.course-861 {
  background-position: -37px -4710px;
}

.course-862 {
  background-position: -145px -4717px;
}

.course-863 {
  background-position: -255px -4730px;
}

.course-864 {
  background-position: -362px -4730px;
}

.course-865 {
  background-position: -476px -4724px;
}
.course-871 {
  background-position: -477px -4838px !important;
}
.course-872 {
  background-position: -584px -4838px !important;
}
.course-873 {
  background-position: -688px -4832px !important;
}
.course-874 {
  background-position: -363px -4838px !important;
}


.icon-linkedin {
  outline: none;
  background-image: url('https://d2o2utebsixu4k.cloudfront.net/assets/icons/icon-sprite.svg');
  background-position: -716px -1145px;
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
}
.icon-star-green {
  background-image: url('https://d2o2utebsixu4k.cloudfront.net/assets/icons/icon-sprite.svg');
  background-position: -372px -1050px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}
.icon-star {
  background-image: url('https://d2o2utebsixu4k.cloudfront.net/assets/icons/icon-sprite.svg');
  background-position: -471px -1050px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

/* Curriculum Icon */
.layout-icons-copy {
  background-image: url('https://d2o2utebsixu4k.cloudfront.net/assets/icons/icon-sprite.svg');
  background-repeat: no-repeat;
}
.icon-curriculum-play-copy {
  background-position: -350px -1154px;
}

.icon-refer-and-earn-img {
  background-position: -186px -1307px;
  width: 133px;
  height: 142px;
}
.icon-refer-and-earn-key-point-1 {
  background-position: -362px -1354px;
  width: 71px;
  height: 47px;
}
.icon-refer-and-earn-key-point-2 {
  background-position: -476px -1354px;
  width: 54px;
  height: 47px;
}
.icon-refer-and-earn-key-point-3 {
  background-position: -573px -1354px;
  width: 42px;
  height: 46px;
}

.blog-refer-and-earn-icons {
  background: url('https://d5jbouauxtwah.cloudfront.net/eyJidWNrZXQiOiJrbm93bGVkZ2VodXQtcHJlcG8tbGl2ZSIsImtleSI6ImFzc2V0cy9pbWFnZXMvaWNvbnMvc3ByaXRlMS0yMC5wbmciLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY292ZXIifX19');
  background-repeat: no-repeat;
}

.icon-refer-and-earn-img-1 {
  background-position: -239px -1362px;
  width: 133px;
  height: 142px;
}
.icon-refer-and-earn-key-point-1-la {
  background-position: -105px -1362px;
  width: 71px;
  height: 47px;
}
.icon-refer-and-earn-key-point-2-la {
  background-position: -703px -1285px;
  width: 54px;
  height: 47px;
}
.icon-refer-and-earn-key-point-3-la {
  background-position: -13px -1362px;
  width: 42px;
  height: 46px;
}

/* Money back pop over*/
.money-back-popover {
  min-width: 518px;
  width: auto !important;
  max-width: 518px !important;
  box-shadow: 0 0 9px 2px rgba(0, 0, 0, 0.1);
  border: 0 none;
}
.money-back-popover h3 {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 12px;
  line-height: 22px;
}
.money-back-popover p {
  color: #333333;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
}
.money-back-popover ul {
  list-style: none;
  padding-left: 0;
  margin-top: 25px;
  margin-bottom: 17px;
}
.money-back-popover li {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #3f3f3f;
  margin-bottom: 7px;
  position: relative;
  padding-left: 8px;
}
.money-back-popover li:before {
  content: '';
  width: 4px;
  height: 4px;
  background: #000000;
  position: absolute;
  left: 0;
  top: 7px;
  border-radius: 100%;
}
.money-back-popover li:last-child {
  margin-bottom: 0;
}
.money-back-popover .money-back-bg {
  background: #000000;
  border-radius: 0 0 4px 4px;
  text-align: center;
  padding: 4px 0;
}
.money-back-popover .money-back-bg span {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 1px 10px 2px;
  border: double 1px transparent;
  border-radius: 10px;
  background-image: linear-gradient(#000000, #000000), radial-gradient(circle at top left, #ff702a, #c507c9);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  color: #ffffff;
  font-style: italic;
  position: relative;
  height: 19px;
  display: inline-block;
  line-height: 16px;
}
.money-back-popover .money-back-bg span:after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -90px;
  width: 90px;
  height: 1px;
  border-radius: 100px 0 0 100px;
  background-image: linear-gradient(to right, #000000 0%, #fc6b31 100%);
}
.money-back-popover .money-back-bg span:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -90px;
  width: 90px;
  height: 1px;
  border-radius: 100px 0 0 100px;
  background-image: linear-gradient(to right, #c90fbd 0%, #000000 100%);
}
.money-back-popover h4 {
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  margin-bottom: 12px;
}
.money-back-popover h2 {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 12px;
  line-height: 22px;
}

.popover {
  min-width: 300px !important;
  max-width: 300px !important;
  border: 0 none;
  box-shadow: 0 0 9px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.popover h3 {
  border-bottom: 0 none !important;
  padding: 0 !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  margin-bottom: 0 !important;
  line-height: 20px;
  color: #000000;
}
.popover p {
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 4px;
}
.popover p b {
  font-weight: 700;
}
.popover .green-clr {
  font-weight: 600;
}

/* button gradient */
.btn {
  border-radius: 4px;
  height: 40px;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #000000;
  text-transform: capitalize;
  letter-spacing: 0px;
  position: relative;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  display: inline-block;
  padding: 8px 0;
  text-align: center;
  font-weight: 600;
  width: 100%;
}

.btn-gradient {
  background: linear-gradient(150deg, #ff702a 0%, #c507c9 100%);
  border: none;
  vertical-align: middle;
}

.btn-gradient:hover:before,
.btn-gradient:focus:before {
  right: 12px;
}

.btn-gradient:before {
  content: '';
  background-image: url('https://d2o2utebsixu4k.cloudfront.net/assets/icons/f-icon-sprite.svg');
  background-repeat: no-repeat;
  width: 9px;
  height: 14px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
  background-position: -682px -4px;
}
@media screen and (min-width: 768px) {
  .btn {
    width: unset;
    padding: 8px 16px;
  }

  .btn-gradient {
    padding: 10px 42px 11px 16px;
  }
}

/* testimonial review section */
.show-more {
  transition: max-height 0.3s linear;
}
.testi-bg-1 {
  background-image: linear-gradient(-45deg, #c7f1ca 0%, #94d399 100%);
}
.testi-bg-1:before {
  background-image: linear-gradient(40deg, #c7f1ca 0%, #78c27d 100%);
}
.testi-bg-1:after {
  background-image: linear-gradient(40deg, #78c27d 0%, #c7f1ca 100%);
}
.testi-bg-2 {
  background-image: linear-gradient(-41deg, #ffbfec 0%, #f7a3de 100%);
}
.testi-bg-2:before {
  background-image: linear-gradient(25deg, #ffbfec 0%, #ff8fde 100%);
}
.testi-bg-2:after {
  background-image: linear-gradient(25deg, #ff8fde 0%, #ffbfec 100%);
}
.testi-bg-3 {
  background-image: linear-gradient(-45deg, #c4b7ff 0%, #b2a3f2 100%);
}
.testi-bg-3:before {
  background-image: linear-gradient(224deg, #a18df4 0%, #c4b7ff 100%);
}
.testi-bg-3:after {
  background-image: linear-gradient(224deg, #c4b7ff 0%, #a18df4 100%);
}
@media only screen and (max-width: 767px) {
  .testi-color::before {
    left: -7px !important;
    bottom: -21px !important;
  }
  .testi-color::after {
    right: -7px !important;
  }
  .money-back-popover .money-back-bg span:before {
    right: -70px;
    width: 70px;
  }
  .money-back-popover .money-back-bg span:after {
    left: -70px;
    width: 70px;
  }
  .money-back-popover {
    min-width: 300px;
    max-width: calc(100vw - 27px) !important;
  }
  .money-back-popover h3 {
    font-weight: 600;
  }
  .money-back-popover p {
    font-size: 12px;
    line-height: 18px;
  }
}
.testi-color:before {
  content: '';
  width: 34px;
  height: 32px;
  position: absolute;
  left: 0px;
  border-radius: 50%;
  bottom: -14px;
  transform: rotate(60deg);
}
.testi-color:after {
  content: '';
  width: 22px;
  height: 14px;
  position: absolute;
  right: 7px;
  border-radius: 50%;
  top: 0;
}

/* white close icon */
.close-icon-white {
  background-image: url('https://d2o2utebsixu4k.cloudfront.net/assets/icons/icon-sprite.svg');
  background-repeat: no-repeat;
  background-position: -624px -1153px;
  width: 12px;
  height: 12px;
}

/* react date picker */
.react-date-picker__inputGroup {
  padding: 8px 12px !important;
}

.react-date-picker__wrapper {
  border: none !important;
  height: 100% !important;
}

.react-date-picker__button {
  display: none !important;
}

.react-calendar__month-view__weekdays abbr {
  text-decoration: none !important;
}

.react-date-picker__calendar {
  width: 250px !important;
}

.react-date-picker__calendar * {
  font-size: 13px !important;
  margin: 0 !important;
}

.react-calendar__year-view__months__month,
.react-calendar__century-view__decades__decade,
.react-calendar__decade-view__years__year {
  padding: 15px 5px !important;
}

.react-calender {
  height: 100px !important;
  /* width: 50% !important; */
}

.react-calendar__viewContainer button {
  border-radius: 4px;
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
}

.react-date-picker__calendar--closed {
  display: none !important;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button,
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  padding: 0px 5px !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__viewContainer button:enabled:hover {
  background-color: rgb(214, 206, 206);
}

.react-calendar__tile {
  padding: 10px 5px !important;
}

.react-calendar__month-view__days__day {
  padding: 3px !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  background-color: none !important;
  color: rgb(187, 186, 186) !important;
}

.react-calendar__tile:disabled {
  background-color: none !important;
  color: rgb(187, 186, 186) !important;
}

.react-date-picker__inputGroup__input {
  cursor: pointer !important;
  height: auto !important;
}

.react-date-picker__inputGroup__input:focus-visible {
  outline: none !important;
}

.react-date-picker__inputGroup__input[type='number'] {
  -moz-appearance: textfield !important; /* Firefox */
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.react-calendar__navigation__arrow:disabled {
  cursor: not-allowed !important;
}

.react-calendar__tile--active {
  background-color: cornflowerblue !important;
  color: white;
}

/* Location Icon */

.layout-icons {
  background-image: url('https://d2o2utebsixu4k.cloudfront.net/assets/icons/icon-sprite.svg');
  background-repeat: no-repeat;
}
.location-icon {
  background-position: -1069px -829px;
  width: 19px;
  height: 22px;
}
.free-skew {
  display: inline-block;
  transform: skew(-6deg);
  background-color: white;
  color: #000;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  line-height: 21px;
  margin: 0 6px;
}

@media screen and (max-width: 766px) {
  .free-skew {
    color: #000000;
    background-color: transparent;
    transform: skew(0);
    padding-left: 0px;
    padding-right: 0px;
    font-style: normal;
    font-size: 14px;
  }
}
.skew {
  display: inline-block;
  transform: skew(-6deg);
  background-color: #fdcb06;
  color: #111111;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
  margin: 0 6px;
}

@media screen and (max-width: 766px) {
  .skew {
    color: #ffda15;
    background-color: transparent;
    transform: skew(0);
    padding-left: 0px;
    padding-right: 0px;
    font-style: normal;
    font-size: 14px;
  }
}

/* input number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.exit-pop-up-gradient {
  background: linear-gradient(90.79deg, #b731bc 0%, #d65774 49.42%, #ec7642 100%);
}
@media screen and (max-width: 768px) {
  .exit-pop-up-gradient-mobile {
    background: linear-gradient(146deg, #ff702a 0%, #c507c9 100%);
  }
}

.exit-popup-whatsapp-checkbox::before {
  background: #586feb !important;
}

.exit-popup-whatsapp-checkbox::after {
  background: url('https://d2o2utebsixu4k.cloudfront.net/blogs/sprites/blogs-icon.svg') !important;
  background-repeat: no-repeat !important;
  background-position: -791px -555px !important;
  width: 12px !important;
  height: 12px !important;
}
.auth-error {
  @apply text-center text-[10px]  font-normal pl-0.5 text-red-500 flex items-start justify-start md:mt-[5px] mt-[2px];
}
/* success pop up */
.success-swiper-container .swiper-wrapper {
  transform: none;
  transition: none;
}

.success-swiper-container
  .swiper-pagination-bullets.swiper-pagination-horizontal {
  left: 10px;
  bottom: 10px;
  width: 50%;
}
.success-pop-up-linear-bg {
  background: linear-gradient(
    89deg,
    rgb(42, 172, 239, 0.2) 0.53%,
    rgba(42, 172, 239, 0) 99.46%
  );
}
.success-swiper-container .swiper-pagination-bullet {
  width: 4px !important;
  height: 4px !important;
  background-color: #181822;
  opacity: 0.2;
  border-radius: 50%;
  margin: 0 1px !important;
}
.success-swiper-container .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 9px !important;
  height: 4px !important;
  background-color: #181822;
  border-radius: 10px;
  opacity: 1;
}
@media only screen and (max-width: 767px) {
  .success-pop-up-linear-bg {
    background: linear-gradient(
      180deg,
      rgb(42, 172, 239, 0.2) 1.53%,
      rgba(42, 172, 239, 0) 99.46%
    );
  }
  .success-swiper-container
    .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 50%;
    width: 100%;
    left: 0;
  }
}
/* purgecss end ignore */
